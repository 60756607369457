<script>
import ApiService from "@services/api.service";
import ImgCarousel from "@/components/ImgCarousel.vue";
import FileList from "@/components/FileList.vue";

export default {
  name: "NewsDetail",
  components: { ImgCarousel, FileList },
  props: {
    newsCode: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      news: {
        code: null,
        title: null,
        content: null,
        newsGroupCode: null,
        priorityRank: null,
        isFeatured: false,
        newsAttachments: []
      },
      snowOption: {
        theme: "snow"
      },
      parameter: {
        newsGroup: []
      }
    };
  },
  computed: {
    editMode() {
      return this.newsCode !== null;
    },
    fileList() {
      return this.news.newsAttachments.filter(x => x.isImage === false);
    },
    imageList() {
      return this.news.newsAttachments.filter(x => x.isImage === true);
    }
  },
  created() {
    if (this.newsCode) {
      this.loadNews();
    }
    this.loadCategory();
  },
  methods: {
    loadNews() {
      this.changeLoader(true);
      let qs = {
        code: this.newsCode
      };
      ApiService.get("News/detail", qs)
        .then(resp => {
          this.news = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },

    loadCategory() {
      this.changeLoader(true);
      ApiService.get("News/group")
        .then(resp => {
          this.parameter.newsGroup = resp;
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },

    async addCarousel(file) {
      this.changeLoader(true);
      let payload = {
        modelObject: {
          newsCode: this.newsCode,
          isImage: true,
          filename: file.filename
        },
        filePayload: file
      };
      ApiService.post("News/attachment", payload)
        .then(() => {
          this.showToast("success", "Upload Image Successful");
          this.loadNews();
        })
        .catch(error => {
          this.showToast("error", error);
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    async addNewFile(file) {
      this.changeLoader(true);
      let payload = {
        modelObject: {
          newsCode: this.newsCode,
          isImage: false,
          filename: file.filename
        },
        filePayload: file
      };
      ApiService.post("News/attachment", payload)
        .then(() => {
          this.showToast("success", "Upload File Successful");
          this.loadNews();
        })
        .finally(() => {
          this.changeLoader(false);
        });
    },
    async removeCarousel(record) {
      if (await this.showConfirm(`Delete File ?`)) {
        this.changeLoader(true);
        let qs = {
          code: record.code
        };
        ApiService.delete("News/attachment", qs)
          .then(() => {
            this.showToast("success", "Delete File Successful");
            this.loadNews();
          })
          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    async removeFile(record) {
      if (await this.showConfirm(`Delete File ?`)) {
        this.changeLoader(true);
        let qs = {
          code: record.code
        };
        ApiService.delete("News/attachment", qs)
          .then(() => {
            this.showToast("success", "Delete File Successful");
            this.loadNews();
          })
          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    save() {
      if (!this.editMode) {
        this.changeLoader(true);
        let payload = {
          title: this.news.title,
          content: this.news.content,
          newsGroupCode: this.news.newsGroupCode,
          isFeatured: this.news.isFeatured
        };
        ApiService.post("news", payload)
          .then(resp => {
            this.showToast("success", "Create Successful");
            this.$router.push({ name: "cms-news-detail", params: { mode: "edit", newsCode: resp.code } });
          })
          .finally(() => {
            this.changeLoader(false);
          });
      } else {
        this.changeLoader(true);
        let payload = {
          code: this.newsCode,
          title: this.news.title,
          content: this.news.content,
          newsGroupCode: this.news.newsGroupCode,
          isFeatured: this.news.isFeatured
        };
        ApiService.put("news", payload)
          .then(() => {
            this.showToast("success", "Edit Successful");
            this.$router.push({ name: "cms-news" });
          })

          .finally(() => {
            this.changeLoader(false);
          });
      }
    },
    clearForm() {
      this.product = {
        code: null,
        productCategoryCode: null,
        description: null
      };
    }
  }
};
</script>

<template>
  <div>
    <b-card>
      <b-row>
        <b-col class="text-center">
          <h2>{{ !editMode ? `Create News` : `Edit News` }}</h2>
        </b-col>
      </b-row>
      <hr />

      <b-form @submit.stop.prevent="save">
        <b-row class="mb-2 mt-2">
          <b-col md="4">
            <label>Title</label>
          </b-col>
          <b-col md="8">
            <b-input v-model="news.title" class="form-control" />
            <small>News title</small>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="4">
            <label>Group</label>
          </b-col>
          <b-col md="8">
            <b-form-select
              v-model="news.newsGroupCode"
              :options="parameter.newsGroup"
              label="description"
              value-field="code"
              text-field="description"
            />
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col offset-md="4" md="8">
            <small>News group</small>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col offset-md="4" md="8">
            <b-form-checkbox v-model="news.isFeatured"> <small>Make Featured </small> </b-form-checkbox>
          </b-col>
        </b-row>
        <b-row class="mb-2">
          <b-col md="4">
            <label>Content</label>
          </b-col>
          <b-col md="8">
            <quill-editor v-model="news.content" :options="snowOption" />

            <small>News Content</small>
          </b-col>
        </b-row>
        <b-row class="d-flex ml-auto mt-5">
          <b-col>
            <router-link :to="{ name: 'cms-news' }">
              <b-btn block variant="danger">Cancel</b-btn>
            </router-link>
          </b-col>
          <b-col>
            <b-btn block type="submit" variant="success">Save</b-btn>
          </b-col>
        </b-row>
      </b-form>
    </b-card>
    <b-row v-if="editMode">
      <b-col md="4">
        <FileList :items="fileList" field="name" @onRemove="removeFile" @onFileChanged="addNewFile" />
      </b-col>
      <b-col md="8">
        <ImgCarousel :items="imageList" field="url" @onRemove="removeCarousel" @onFileChanged="addCarousel" />
      </b-col>
    </b-row>
  </div>
</template>
